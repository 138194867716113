export const ACCEPTED_FILE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
]

export const PRIVACY_POLICY_URL = '/TODO' // TODO
export const URL_HASH_FOR_COOKIE_MANAGEMENT = '#cookie-management'

export const CGU_URL =
  'https://cdn1.purchease.com/production/terms/CGU_Salsa.html'
